<template>
  <div class="invoice">
    <v-card :loading="loading" class="mb-3">
      <v-card-title>Nota Penjualan</v-card-title>
      <v-divider></v-divider>
      <template v-if="!loading && invoice != null">
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>No Transaksi :</td>
                  <td>{{ invoice.trans_no }}</td>
                </tr>
                <tr>
                  <td>Referensi :</td>
                  <td>{{ invoice.reference }}</td>
                </tr>
                <tr>
                  <td>Tanggal :</td>
                  <td>{{ sql2date(invoice.tran_date) }}</td>
                </tr>
                <tr>
                  <td>Customer :</td>
                  <td>{{ invoice.br_name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </template>
    </v-card>

    <template v-if="!loading && invoice != null">
      <v-card class="mb-3" color="#15263b">
        <v-card-title class="pb-1">Total</v-card-title>

        <v-card-text>
          <h2>{{ invoice.total }}</h2>
        </v-card-text>
      </v-card>

      <v-card class="mb-3">
        <v-card-title>Items</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-card v-for="item in invoice.items" :key="item.id" class="mb-3">
            <v-card-text class="pa-0">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="fit">Kode</td>
                      <td>{{ item.stock_id }}</td>
                    </tr>
                    <tr>
                      <td class="fit">Nama</td>
                      <td>{{ item.description }}</td>
                    </tr>
                    <tr>
                      <td class="fit">Kuantitas</td>
                      <td class="text-right">{{ item.quantity }}</td>
                    </tr>
                    <tr>
                      <td class="fit">Harga Satuan</td>
                      <td class="text-right">{{ item.unit_price }}</td>
                    </tr>
                    <tr>
                      <td class="fit">Diskon</td>
                      <td class="text-right">{{ item.discount }}</td>
                    </tr>
                    <tr>
                      <td class="fit">Total</td>
                      <td class="text-right">{{ item.total }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Invoice',

  data: () => ({
    loading: false,
    invoice: null,
  }),

  computed: {
    transNo() {
      return this.$route.params.transNo
    },
  },

  mounted() {
    this.getInvoice()
  },

  methods: {
    getInvoice() {
      this.loading = true

      this.$http
        .get('/sales/invoice/' + this.transNo)
        .then(({ data }) => {
          this.invoice = data.invoice
        })
        .catch((err) => {
          console.log(err)
        })
        .then(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.fit {
  width: 1%;
  white-space: nowrap;
}
</style>
